import {CSVLink} from 'react-csv';
import {Link, useSearchParams } from 'react-router-dom';
import { FileExcelOutlined} from '@ant-design/icons';
import { useQuery} from '@apollo/client';
import {Button, DatePicker, Input, Space, Table} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';

import {ENUM_GETMONEYKZORDER_CHECKSTATUS, ENUM_GETMONEYKZORDER_STATUS} from '../../../../__generated__/global-types';
import {getMoneyKzOrders, getMoneyKzOrdersVariables} from '../../../../graphql/__generated__/getMoneyKzOrders';
import {QUERY_GETMONEY_KZ_ORDERS} from '../../../../graphql/getmoney-kz';
import { toGetMoneyKzUser} from '../../../../routes/routes'

import {filtersToQuery, filtersToSearchParams, searchParamsToFilters } from './filter';

import cls from './GetMoneyKZOrders.module.scss'

export function displayGetMoneyKzOrderStatus(status: ENUM_GETMONEYKZORDER_STATUS) {
    if (!status) return ''
    let name = ''
    switch (status) {
        case ENUM_GETMONEYKZORDER_STATUS.CREATED_1:
            name = '🕓 Создано';
            break;
        case ENUM_GETMONEYKZORDER_STATUS.AWAIT_2:
            name = '🕓 В ожидании';
            break;
        case ENUM_GETMONEYKZORDER_STATUS.PAID_3:
            name = '✅ Оплачено';
            break;
        case ENUM_GETMONEYKZORDER_STATUS.ERROR_4:
            name = '❌ Ошибка';
            break;
        case ENUM_GETMONEYKZORDER_STATUS.REFUND_5:
            name = '↩️ Возврат';
            break;
    }
    return name
}

export function displayGetMoneyKzCheckStatus(status: ENUM_GETMONEYKZORDER_CHECKSTATUS) {
    if (!status) return ''
    let name = ''
    switch (status) {
        case ENUM_GETMONEYKZORDER_CHECKSTATUS.CREATED_1:
            name = '🕓 Создано';
            break;
        case ENUM_GETMONEYKZORDER_CHECKSTATUS.SENDED_2:
            name = '✅ Сформирован';
            break;
        case ENUM_GETMONEYKZORDER_CHECKSTATUS.SENDED_REFUND_3:
            name = '⬅ Возврат';
            break;
        case ENUM_GETMONEYKZORDER_CHECKSTATUS.ERROR_4:
            name = '❌ Ошибка';
            break;
    }
    return name
}

export const GetMoneyKZOrders = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);

    const query = useQuery<getMoneyKzOrders, getMoneyKzOrdersVariables>(QUERY_GETMONEY_KZ_ORDERS, {
        variables: {
            filters: {
                ...filtersToQuery(filters),
            },
            sort: ['createdAt:DESC'],
            pagination: {
                limit: 10000
            }
        }
    })

    const dataSource = _.map(query.data?.getMoneyKzOrders?.data, item => ({
        ...item.attributes,
        key: item.id,
        createdAt: dayjs(item.attributes?.createdAt).format('DD.MM.YYYY HH:mm'),
        paidAt: item.attributes?.paidAt ? dayjs(item.attributes?.paidAt).format('DD.MM.YYYY HH:mm') : '',
        refundAt: item.attributes?.refundAt ? dayjs(item.attributes?.refundAt).format('DD.MM.YYYY HH:mm') : '',
        test: item.attributes?.test ? '❗' : '',
        FIO: item.attributes?.user?.data?.attributes?.FIO,
        phone: item.attributes?.user?.data?.attributes?.phone,
        status: displayGetMoneyKzOrderStatus(item.attributes?.status!),
        checkStatus: displayGetMoneyKzCheckStatus(item.attributes?.checkStatus!),
    }))

    const columns = [
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'UID',
            dataIndex: 'UID',
            key: 'UID',
        },
        {
            title: 'ФИО',
            dataIndex: 'FIO',
            key: 'FIO',
            render: (value:string, record:any) => <Link to={toGetMoneyKzUser(record.user?.data?.id)}>{value}</Link>
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Оплата',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Чек',
            dataIndex: 'checkStatus',
            key: 'checkStatus',
        },
        {
            title: 'Дата оплаты',
            dataIndex: 'paidAt',
            key: 'paidAt',
        },
        {
            title: 'Дата возврата',
            dataIndex: 'refundAt',
            key: 'refundAt',
        },
        {
            title: 'Тестовый платеж',
            dataIndex: 'test',
            key: 'test',
        },
    ];

    const headers = _.map(columns, item => ({label: item.title, key: item.key}))

    const onSearchChange = (data:any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            search: data,
        }))
    }

    const onPeriodChange = (data:any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            period: data,
        }))
    }

    return (
        <>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Space>
                        <DatePicker.RangePicker onChange={onPeriodChange} allowClear={false} value={[filters.period[0], filters.period[1]]} format={'DD MMM YYYY'}/>
                        <Input.Search onSearch={onSearchChange} allowClear={true}/>
                    </Space>
                </div>
                <div className={cls.right}>
                    <CSVLink data={dataSource} headers={headers} filename={'GetMoney-KZ-orders.csv'}>
                        <Button icon={<FileExcelOutlined/>}>Скачать CSV</Button>
                    </CSVLink>
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} loading={query.loading} />
           
        </>

    )

}