import {gql} from '@apollo/client';
export const QUERY_DELIVERY_FILES = gql`
    query deliveryFiles($filters: DeliveryFileFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = [], $paginationRecords: PaginationArg = {}) {
        deliveryFiles(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    createdAt
                    media {
                        data {
                            id
                            attributes {
                                url
                                name
                            }
                        }
                    }
                    records (pagination: $paginationRecords) {
                        data {
                            id
                            attributes {
                                sendStatus
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }
    }
`;

export const QUERY_DELIVERY_RECORDS = gql`
    query deliveryRecords($filters: DeliveryRecordFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        deliveryRecords(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    createdAt
                    agent
                    status
                    product
                    CertNumber
                    CertDate
                    FIO
                    Passport
                    Birthday
                    Phone
                    InsuranceAmount
                    Cost
                    Duration
                    data
                    CertFilename
                    sendStatus
                    code
                    activated
                    activatedAt
                    emailStatus
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }
    }
`;


export const MUTATION_UPLOAD = gql`
    mutation upload($field: String, $file: Upload!, $info: FileInfoInput, $ref: String, $refId: ID) {
        upload(field: $field, file: $file, info: $info, ref: $ref, refId: $refId) {
            data {
                id
                attributes {
                    url
                }
            }
        }
    }
`;

export const MUTATION_CREATE_DELIVERY_FILE = gql`
    mutation createDeliveryFile($data: DeliveryFileInput!, $paginationRecords: PaginationArg = {}) {
        createDeliveryFile(data: $data) {
            data {
                id
                attributes {
                    records (pagination: $paginationRecords) {
                        data {
                            id
                            attributes {
                                sendStatus
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const MUTATION_GENERATE_RECORD_FILE = gql`
    mutation generateRecordFile($id: ID!) {
        generateRecordFile(id: $id) {
            done
        }
    }
`;