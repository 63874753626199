import React from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';

import {ONLYCASH_RU_LEADS, ONLYCASH_RU_SHOWCASES } from '../../../routes/routes';

import cls from '../../Home/Home.module.scss'

export const Menu = () => (

    <>
        <div className={cls.home}>

            <div className={cls.group}>

            <Link to={ONLYCASH_RU_SHOWCASES} className={cn([cls.card, cls.bgTwo])}>Витрина</Link>
            <Link to={ONLYCASH_RU_LEADS} className={cn([cls.card, cls.bgThree])}>Лиды</Link>

            </div>

        </div>
    </>

)