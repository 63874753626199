import {useMemo} from 'react';
import { useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {Divider, Skeleton, Table} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';

import {getMoneyKzLeads, getMoneyKzLeadsVariables} from '../../../../graphql/__generated__/getMoneyKzLeads';
import {getMoneyKzOrders, getMoneyKzOrdersVariables} from '../../../../graphql/__generated__/getMoneyKzOrders';
import {getMoneyKzReg, getMoneyKzRegVariables} from '../../../../graphql/__generated__/getMoneyKzReg';
import {QUERY_GETMONEY_KZ_LEADS, QUERY_GETMONEY_KZ_ORDERS, QUERY_GETMONEY_KZ_REG} from '../../../../graphql/getmoney-kz';
import {DisplayDate} from '../../../../helpers/DisplayDate';
import {displayGetMoneyKzLeadStatus} from '../Lead/GetMoneyKZLeads';
import {displayGetMoneyKzOrderStatus} from '../Order/GetMoneyKZOrders';

import cls from './GetMoneyKZRegs.module.scss'


export const GetMoneyKzReg = () => {

    const {id} = useParams()

    const query = useQuery<getMoneyKzReg, getMoneyKzRegVariables>(QUERY_GETMONEY_KZ_REG, {
        variables: {
            id: id!,
        },
    })

    const orders = useQuery<getMoneyKzOrders, getMoneyKzOrdersVariables>(QUERY_GETMONEY_KZ_ORDERS, {
        variables: {
            filters: {
                user: {id: {eq: id}}
            },
            sort: ['createdAt:DESC'],
            pagination: {
                limit: 500
            }
        }
    })

    const leads = useQuery<getMoneyKzLeads, getMoneyKzLeadsVariables>(QUERY_GETMONEY_KZ_LEADS, {
        variables: {
            filters: {
                user: {id: {eq: id}}
            },
            sort: ['createdAt:DESC'],
            pagination: {
                limit: 500
            }
        }
    })

    const ordersData = useMemo(() => _.map(orders.data?.getMoneyKzOrders?.data, item => ({
        ...item.attributes,
        key: item.id,
        createdAt: dayjs(item.attributes?.createdAt).format('DD.MM.YYYY HH:mm'),
        paidAt: item.attributes?.paidAt ? dayjs(item.attributes?.paidAt).format('DD.MM.YYYY HH:mm') : '',
        refundAt: item.attributes?.refundAt ? dayjs(item.attributes?.refundAt).format('DD.MM.YYYY HH:mm') : '',
        test: item.attributes?.test ? '❗' : '',
        status: displayGetMoneyKzOrderStatus(item.attributes?.status!),
    })), [orders.data?.getMoneyKzOrders?.data])

    const leadsData = useMemo(() => _.map(leads.data?.getMoneyKzLeads?.data, item => ({
        ...item.attributes,
        key: item.id,
        createdAt: DisplayDate(item.attributes?.createdAt),
        registerAt: DisplayDate(item.attributes?.registerAt),
        loginAt: DisplayDate(item.attributes?.loginAt),
        orderAt: DisplayDate(item.attributes?.orderAt),
        paidAt: DisplayDate(item.attributes?.paidAt),
        refundAt: DisplayDate(item.attributes?.refundAt),
        status: displayGetMoneyKzLeadStatus(item.attributes?.status!),
    })), [leads.data?.getMoneyKzLeads?.data])

    const ordersColumns = [
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'UID',
            dataIndex: 'UID',
            key: 'UID',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Дата оплаты',
            dataIndex: 'paidAt',
            key: 'paidAt',
        },
        {
            title: 'Дата возврата',
            dataIndex: 'refundAt',
            key: 'refundAt',
        },
        {
            title: 'Тестовый платеж',
            dataIndex: 'test',
            key: 'test',
        },
    ];

    const leadsColumns = [
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'UID',
            dataIndex: 'session',
            key: 'session',
        },
        {
            title: 'Провайдер',
            dataIndex: 'provider',
            key: 'provider',
        },
        {
            title: 'Посещение',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Регистрация',
            dataIndex: 'registerAt',
            key: 'registerAt',
        },
        {
            title: 'Логин',
            dataIndex: 'loginAt',
            key: 'loginAt',
        },
        {
            title: 'Заказ',
            dataIndex: 'orderAt',
            key: 'orderAt',
        },
        {
            title: 'Оплата',
            dataIndex: 'paidAt',
            key: 'paidAt',
        },
        {
            title: 'Возврат',
            dataIndex: 'refundAt',
            key: 'refundAt',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    if (query.loading) return <Skeleton active/>

    return (
        <>
            <h2>{query.data?.getMoneyKzReg?.data?.attributes?.FIO}</h2>
            <div className={cls.table}>
                    <div>Телефон</div>
                    <div className={'bold'}>{query.data?.getMoneyKzReg?.data?.attributes?.phone}</div>
                    <div>Дата рождения</div>
                    <div className={'bold'}>{dayjs(query.data?.getMoneyKzReg?.data?.attributes?.birthday).format('DD.MM.YYYY')}</div>
                    <div>Пол</div>
                    <div className={'bold'}>{query.data?.getMoneyKzReg?.data?.attributes?.sex === 'male' ? 'Мужской' : 'Женский'}</div>
                    <div>Город</div>
                    <div className={'bold'}>{query.data?.getMoneyKzReg?.data?.attributes?.city}</div>
            </div>
            <Divider/>
            <h3>Оплаты</h3>
            <Table dataSource={ordersData} columns={ordersColumns} loading={orders.loading} />

            <Divider/>
            <h3>Лиды</h3>
            <Table dataSource={leadsData} columns={leadsColumns} loading={leads.loading} />
        </>
    )

}