import {useNavigate} from 'react-router';
import {useMutation, useQuery} from '@apollo/client';
import {Skeleton} from 'antd';

import {getMoneyKzShowcase, getMoneyKzShowcaseVariables} from '../../../../../graphql/__generated__/getMoneyKzShowcase';
import {
    updateGetMoneyKzShowcase,
    updateGetMoneyKzShowcaseVariables
} from '../../../../../graphql/__generated__/updateGetMoneyKzShowcase';
import {MUTATION_UPDATE_GETMONEY_KZ_SHOWCASE, QUERY_GETMONEY_KZ_SHOWCASE} from '../../../../../graphql/getmoney-kz';
import {imgUrl} from '../../../../../helpers/imgUrl';

import {GetMoneyKzShowcaseForm,IOffer} from './GetMoneyKzShowcaseForm';

interface IOfferEdit {
    id: string
}

export const GetMoneyKzShowcaseEdit = ({id}: IOfferEdit) => {

    const query = useQuery<getMoneyKzShowcase,getMoneyKzShowcaseVariables>(QUERY_GETMONEY_KZ_SHOWCASE, {
        variables: {
            id: id,
        },
    })

    const initValues:IOffer = {
        headline: query.data?.getMoneyKzShowcase?.data?.attributes?.headline!,
        logo: {
            id: query.data?.getMoneyKzShowcase?.data?.attributes?.logo.data?.id!,
            url: imgUrl(query.data?.getMoneyKzShowcase?.data?.attributes?.logo.data?.attributes?.url!),
        },
        age: query.data?.getMoneyKzShowcase?.data?.attributes?.age!,
        term: query.data?.getMoneyKzShowcase?.data?.attributes?.term!,
        percent: query.data?.getMoneyKzShowcase?.data?.attributes?.percent!,
        URL: query.data?.getMoneyKzShowcase?.data?.attributes?.URL!
    }

    const [update] = useMutation<updateGetMoneyKzShowcase,updateGetMoneyKzShowcaseVariables>(MUTATION_UPDATE_GETMONEY_KZ_SHOWCASE)
    const navigate = useNavigate()

    const onFinish = async (data: IOffer) => {
        await update({
            variables: {
                id: id,
                data: {
                    ...data,
                    logo: data.logo.id,
                },
            },
            onCompleted: () => {
                navigate(-1)
            },
        })
    }

    if (query.loading) return <Skeleton active/>

    return (
        <>
            <h2>Редактировать оффер</h2>
            <GetMoneyKzShowcaseForm values={initValues} action={'Сохранить'} onFinish={onFinish}/>
        </>
    )

}