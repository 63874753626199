import {useNavigate} from 'react-router';
import {useMutation, useQuery} from '@apollo/client';
import {Skeleton} from 'antd';

import {onlyCashRuShowcase, onlyCashRuShowcaseVariables} from '../../../../../graphql/__generated__/onlyCashRuShowcase';
import {
    updateOnlyCashRuShowcase,
    updateOnlyCashRuShowcaseVariables
} from '../../../../../graphql/__generated__/updateOnlyCashRuShowcase';
import {MUTATION_UPDATE_OK_RU_OFFER, QUERY_ONLYCASH_RU_SHOWCASE} from '../../../../../graphql/onlycash-ru';
import {imgUrl} from '../../../../../helpers/imgUrl';

import {IOffer, OfferForm} from './OfferForm';

interface IOfferEdit {
    id: string
}

export const OfferEdit = ({id}: IOfferEdit) => {

    const query = useQuery<onlyCashRuShowcase,onlyCashRuShowcaseVariables>(QUERY_ONLYCASH_RU_SHOWCASE, {
        variables: {
            id: id,
        },
    })

    const initValues:IOffer = {
        headline: query.data?.onlyCashRuShowcase?.data?.attributes?.headline!,
        logo: {
            id: query.data?.onlyCashRuShowcase?.data?.attributes?.logo.data?.id!,
            url: imgUrl(query.data?.onlyCashRuShowcase?.data?.attributes?.logo.data?.attributes?.url!),
        },
        age: query.data?.onlyCashRuShowcase?.data?.attributes?.age!,
        term: query.data?.onlyCashRuShowcase?.data?.attributes?.term!,
        percent: query.data?.onlyCashRuShowcase?.data?.attributes?.percent!,
        URL: query.data?.onlyCashRuShowcase?.data?.attributes?.URL!
    }

    const [updateOffer] = useMutation<updateOnlyCashRuShowcase,updateOnlyCashRuShowcaseVariables>(MUTATION_UPDATE_OK_RU_OFFER)
    const navigate = useNavigate()

    const onFinish = async (data: IOffer) => {
        await updateOffer({
            variables: {
                id: id,
                data: {
                    ...data,
                    logo: data.logo.id,
                },
            },
            onCompleted: () => {
                navigate(-1)
            },
        })
    }

    if (query.loading) return <Skeleton active/>

    return (
        <>
            <h2>Редактировать оффер</h2>
            <OfferForm values={initValues} action={'Сохранить'} onFinish={onFinish}/>
        </>
    )

}