
import dayjs, {Dayjs} from 'dayjs';

import {DeliveryFileFiltersInput} from '../../../__generated__/global-types';

export interface IDeliveryFilesFilters {
    search: string | null
    period: Dayjs[] | null
    file: string | null
}

export const defaultIndexFilter: IDeliveryFilesFilters = {
    search: null,
    period: null,
    file: null,
}

export const isChangedFilter = (filter: IDeliveryFilesFilters) => {
    if (filter.search !== defaultIndexFilter.search) return true
    if (filter.period !== defaultIndexFilter.period) return true
    if (filter.file !== defaultIndexFilter.file) return true
}

export const filtersToSearchParams = (filter: IDeliveryFilesFilters) => ({
    ...(filter.search && {search: filter.search}),
    ...(filter.file && {file: filter.file}),
    ...(filter.period && {start: filter.period[0].format('YYYY-MM-DD'), end: filter.period[1].format('YYYY-MM-DD')}),
})

export const searchParamsToFilters = (params: URLSearchParams) => ({
    search: params.get('search') ? params.get('search') : defaultIndexFilter.search,
    file: params.get('file') ? params.get('file') : defaultIndexFilter.file,
    period: params.get('start') && params.get('end') ? [dayjs(params.get('start')), dayjs(params.get('end'))] : defaultIndexFilter.period,
}) as IDeliveryFilesFilters

export const filtersToQuery = (filter: IDeliveryFilesFilters) => ({
    ...(filter.search && {
        or: [{
            FIO: {contains: filter.search}
        }, {
            CertNumber: {contains: filter.search}
        }, {
            Phone: {contains: filter.search}
        },  {
            email: {contains: filter.search}
        },  {
            id: {contains: filter.search}
        }, ]
    }),
    ...(filter.file && {file: {id: {eq: filter.file}}}),
    ...(filter.period && {createdAt: {between: [filter.period[0].format(), filter.period[1].format()]}}),
}) as DeliveryFileFiltersInput

