import dayjs, {Dayjs} from 'dayjs';

import {OnlyCashKzLeadFiltersInput} from '../../../../__generated__/global-types';

export interface IOnlyCashKZLeadFilters {
    id: string | null
    search: string | null
    period: Dayjs[]
}

export const defaultOnlyCashKZLeadFilter: IOnlyCashKZLeadFilters = {
    id: null,
    search: null,
    period: [dayjs().subtract(1, 'week'), dayjs()],
}

export const isChangedFilter = (filter: IOnlyCashKZLeadFilters) => {
    if (filter.id !== defaultOnlyCashKZLeadFilter.search) return true
    if (filter.search !== defaultOnlyCashKZLeadFilter.search) return true
    if (filter.period !== defaultOnlyCashKZLeadFilter.period) return true
}

export const filtersToSearchParams = (filter: IOnlyCashKZLeadFilters) => ({
    ...(filter.id && {id: filter.id}),
    ...(filter.search && {search: filter.search}),
    ...(filter.period && {start: filter.period[0].format('YYYY-MM-DD'), end: filter.period[1].format('YYYY-MM-DD')}),
})

export const searchParamsToFilters = (params: URLSearchParams) => ({
    id: params.get('id') ? params.get('id') : defaultOnlyCashKZLeadFilter.search,
    search: params.get('search') ? params.get('search') : defaultOnlyCashKZLeadFilter.search,
    period: params.get('start') && params.get('end') ? [dayjs(params.get('start')), dayjs(params.get('end'))] : defaultOnlyCashKZLeadFilter.period,
}) as IOnlyCashKZLeadFilters

export const filtersToQuery = (filter: IOnlyCashKZLeadFilters) => ({
    ...(filter.id && {id: {eq: filter.id}}),
    ...(filter.search && {
        or: [{
            session: {contains: filter.search}
        }, {
            id: {contains: filter.search}
        },]
    }),
    ...(filter.period && {createdAt: {between: [filter.period[0].startOf('day').format(), filter.period[1].endOf('day').format()]}}),
}) as OnlyCashKzLeadFiltersInput

