import React from 'react';
// eslint-disable-next-line import/no-named-as-default
import {provider, useInstance} from 'react-ioc';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {ApolloClient, ApolloProvider} from '@apollo/client';
import {ConfigProvider, Skeleton} from 'antd';
import ru_RU from 'antd/locale/ru_RU'
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru'
import ApolloClientDI from 'graphql/ApolloClientDI';
import {observer} from 'mobx-react-lite';
import {Home} from 'pages/Home/Home';
import {
    ACTIVATE,
    CONSULTATION, DELIVERY_FILES,
    FORGET, GETMONEY_KZ,
    GETMONEY_KZ_LEADS, GETMONEY_KZ_ORDERS, GETMONEY_KZ_PREVIEW,
    GETMONEY_KZ_REG, GETMONEY_KZ_REGS, GETMONEY_KZ_SHOWCASE, GETMONEY_KZ_SHOWCASES,
    HOME,
    LOGIN,
    LOSS,
    ONLYCASH_KZ,
    ONLYCASH_KZ_LEAD,
    ONLYCASH_KZ_REG,
    ONLYCASH_RU, ONLYCASH_RU_LEADS, ONLYCASH_RU_SHOWCASE, ONLYCASH_RU_SHOWCASES,
    PARTNER, RECORDS,
    REFUND,
    SFTP, SMS_FILES, SMS_INFOS
} from 'routes/routes';
import {Store} from 'store/Store';
import StoreDI from 'store/StoreDI';

import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

import {Layout} from '../layout/Layout/Layout';
import {LoginLayout} from '../layout/Layout/LoginLayout';
import {Records} from '../pages/Deal/Records/Records';
import {Infos} from '../pages/Deal/SmsInfos/Infos';
import {Forget} from '../pages/Forget/Forget';
import {Login} from '../pages/Login/Login';
import {Files} from '../pages/Partners/Delivery/Files/Files';
import {SFTPSiab} from '../pages/Partners/SFTP/SFTPSiab';
import {SmsFiles} from '../pages/Partners/SmsInfo/Files/SmsFiles';
import {GetMoneyKZMenu} from '../pages/Projects/GetMoneyKZ/GetMoneyKZMenu';
import {GetMoneyKZLeads} from '../pages/Projects/GetMoneyKZ/Lead/GetMoneyKZLeads';
import { GetMoneyKZOrders } from '../pages/Projects/GetMoneyKZ/Order/GetMoneyKZOrders';
import {GetMoneyKzReg} from '../pages/Projects/GetMoneyKZ/Reg/GetMoneyKzReg';
import {GetMoneyKZRegs} from '../pages/Projects/GetMoneyKZ/Reg/GetMoneyKZRegs';
import {GetMoneyKZShowcases} from '../pages/Projects/GetMoneyKZ/Showcase/GetMoneyKZShowcases';
import {GetMoneyKzShowcase} from '../pages/Projects/GetMoneyKZ/Showcase/Offer/GetMoneyKzShowcase';
import {Cards} from '../pages/Projects/GetMoneyKZ/Showcase/Preview/Cards';
import {OnlyCashKZLead} from '../pages/Projects/OnlyCashKZ/Lead/OnlyCashKZLead';
import {OnlyCashKZMenu} from '../pages/Projects/OnlyCashKZ/OnlyCashKZMenu';
import { OnlyCashKZ } from '../pages/Projects/OnlyCashKZ/Reg/OnlyCashKZ';
import { Leads } from '../pages/Projects/OnlyCashRU/Lead/Leads';
import {Menu} from '../pages/Projects/OnlyCashRU/Menu';
import {Offer} from '../pages/Projects/OnlyCashRU/Showcase/Offer/Offer';
import {Showcase} from '../pages/Projects/OnlyCashRU/Showcase/Showcase';
import {Activate} from '../pages/SiteForms/Activate/Activate';
import {Consultation} from '../pages/SiteForms/Consultation/Consultation';
import {Loss} from '../pages/SiteForms/Loss/Loss';
import {Partner} from '../pages/SiteForms/Partner/Partner';
import {Refund} from '../pages/SiteForms/Refund/Refund';

import './App.css';

function App() {

    const store = useInstance(Store);
    const {hydrated, auth} = store;
    const {hasAuthToken} = auth;

    const apolloClient = useInstance(ApolloClient);

    dayjs.locale(ru)

    if (!hydrated) {
        return (
            <Skeleton active={true}/>
        );
    }

    return (
        <ApolloProvider client={apolloClient}>

            <ConfigProvider
                locale={ru_RU}
                theme={{
                    token: {
                        colorPrimary: '#00A6CE',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 14,
                    },
                }}>
                <BrowserRouter>
                    <ScrollToTop/>

                    {!hasAuthToken && (
                        <LoginLayout>
                            <Routes>
                                <Route path={LOGIN} element={<Login/>}/>
                                <Route path={FORGET} element={<Forget/>}/>
                                <Route path="*" element={<Navigate to={LOGIN}/>}/>
                            </Routes>
                        </LoginLayout>
                    )}

                    {hasAuthToken && (
                        <Layout>
                            <Routes>
                                <Route path={HOME} element={<Home/>}/>
                                <Route path={REFUND} element={<Refund/>}/>
                                <Route path={LOSS} element={<Loss/>}/>
                                <Route path={ACTIVATE} element={<Activate/>}/>
                                <Route path={CONSULTATION} element={<Consultation/>}/>
                                <Route path={PARTNER} element={<Partner/>}/>

                                <Route path={ONLYCASH_KZ} element={<OnlyCashKZMenu/>}/>
                                <Route path={ONLYCASH_KZ_REG} element={<OnlyCashKZ/>}/>
                                <Route path={ONLYCASH_KZ_LEAD} element={<OnlyCashKZLead/>}/>

                                <Route path={ONLYCASH_RU} element={<Menu/>}/>
                                <Route path={ONLYCASH_RU_SHOWCASES} element={<Showcase/>}/>
                                <Route path={ONLYCASH_RU_SHOWCASE} element={<Offer/>}/>
                                <Route path={ONLYCASH_RU_LEADS} element={<Leads/>}/>

                                <Route path={GETMONEY_KZ} element={<GetMoneyKZMenu/>}/>
                                <Route path={GETMONEY_KZ_SHOWCASES} element={<GetMoneyKZShowcases/>}/>
                                <Route path={GETMONEY_KZ_PREVIEW} element={<Cards/>}/>
                                <Route path={GETMONEY_KZ_SHOWCASE} element={<GetMoneyKzShowcase/>}/>
                                <Route path={GETMONEY_KZ_REGS} element={<GetMoneyKZRegs/>}/>
                                <Route path={GETMONEY_KZ_REG} element={<GetMoneyKzReg/>}/>
                                <Route path={GETMONEY_KZ_ORDERS} element={<GetMoneyKZOrders/>}/>
                                <Route path={GETMONEY_KZ_LEADS} element={<GetMoneyKZLeads/>}/>

                                <Route path={DELIVERY_FILES} element={<Files/>}/>
                                <Route path={RECORDS} element={<Records/>}/>

                                <Route path={SMS_FILES} element={<SmsFiles/>}/>
                                <Route path={SMS_INFOS} element={<Infos/>}/>

                                <Route path={SFTP} element={<SFTPSiab/>}/>

                                <Route path="*" element={<Navigate to={HOME}/>}/>
                            </Routes>
                        </Layout>

                    )}


                </BrowserRouter>
            </ConfigProvider>
        </ApolloProvider>
    );
}

export default provider(
    StoreDI(),
    ApolloClientDI()
)(observer(App));
