import {CSVLink} from 'react-csv';
import { useSearchParams } from 'react-router-dom';
import {FileExcelOutlined} from '@ant-design/icons';
import { useQuery} from '@apollo/client';
import {Button, DatePicker, Input, Space, Table} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';

import {consultations, consultationsVariables} from '../../../graphql/__generated__/consultations';
import {QUERY_CONSULTATION} from '../../../graphql/consultation';

import {filtersToQuery, filtersToSearchParams, searchParamsToFilters } from './filter';

import cls from './Consultation.module.scss'

export const Consultation = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);

    const query = useQuery<consultations, consultationsVariables>(QUERY_CONSULTATION, {
        variables: {
            filters: {
                ...filtersToQuery(filters),
            },
            pagination: {
                limit: 10000
            }
        }
    })

    const dataSource = _.map(query.data?.consultations?.data, item => ({
        ...item.attributes,
        key: item.id,
        createdAt: dayjs(item.attributes?.createdAt).format('DD.MM.YYYY HH:mm'),
        Certificate_Date: dayjs(item.attributes?.Certificate_Date).format('DD.MM.YYYY'),
    }))

    const columns = [
        {
            title: 'Дата заявки',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'ФИО',
            dataIndex: 'FIO',
            key: 'FIO',
        },
        {
            title: 'Телефон',
            dataIndex: 'Phone',
            key: 'Phone',
        },
        {
            title: 'Номер сертификата',
            dataIndex: 'Certificate_Number',
            key: 'Certificate_Number',
        },
        {
            title: 'Дата выдачи',
            dataIndex: 'Certificate_Date',
            key: 'Certificate_Date',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    const headers = _.map(columns, item => ({label: item.title, key: item.key}))

    const onSearchChange = (data:any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            search: data,
        }))
    }

    const onPeriodChange = (data:any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            period: data,
        }))
    }

    return (
        <>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Space>
                        <DatePicker.RangePicker onChange={onPeriodChange} allowClear={false} value={[filters.period[0], filters.period[1]]} format={'DD MMM YYYY'}/>
                        <Input.Search onSearch={onSearchChange} allowClear={true}/>
                    </Space>
                </div>
                <div className={cls.right}>
                    <CSVLink data={dataSource} headers={headers} filename={'FTS-Consultation.csv'}>
                        <Button icon={<FileExcelOutlined/>}>Скачать CSV</Button>
                    </CSVLink>
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} loading={query.loading} />
           
        </>

    )

}