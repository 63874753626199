import {gql} from '@apollo/client';
export const QUERY_GETMONEY_KZ_SHOWCASES = gql`
    query getMoneyKzShowcases($filters: GetMoneyKzShowcaseFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        getMoneyKzShowcases(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    headline
                    logo {
                        data {
                            id
                            attributes {
                                url
                            }
                        }
                    }
                    age
                    term
                    URL
                    position
                    percent
                    free
                    paid
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }
    }
`;
export const QUERY_GETMONEY_KZ_SHOWCASE = gql`
    query getMoneyKzShowcase($id: ID!) {
        getMoneyKzShowcase(id: $id) {
            data {
                id
                attributes {
                    headline
                    logo {
                        data {
                            id
                            attributes {
                                url
                            }
                        }
                    }
                    age
                    term
                    URL
                    position
                    percent
                    free
                    paid
                }
            }
        }
    }
`;

export const MUTATION_CREATE_GETMONEY_KZ_SHOWCASE = gql`
    mutation createGetMoneyKzShowcase($data: GetMoneyKzShowcaseInput!) {
        createGetMoneyKzShowcase(data: $data) {
            data {
                id
            }
        }
    }
`;

export const MUTATION_UPDATE_GETMONEY_KZ_SHOWCASE = gql`
    mutation updateGetMoneyKzShowcase($id: ID!, $data: GetMoneyKzShowcaseInput!) {
        updateGetMoneyKzShowcase(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

export const QUERY_GETMONEY_KZ_REGS = gql`
    query getMoneyKzRegs($filters: GetMoneyKzRegFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        getMoneyKzRegs(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    createdAt
                    FIO
                    phone
                    birthday
                    sex
                    city
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }
    }
`;

export const QUERY_GETMONEY_KZ_ORDERS = gql`
    query getMoneyKzOrders($filters: GetMoneyKzOrderFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        getMoneyKzOrders(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    createdAt
                    UID
                    user {
                        data {
                            id
                            attributes {
                                FIO
                                phone
                            }
                        }
                    }
                    status
                    paidAt
                    refundAt
                    test
                    amount
                    customerEmail
                    checkStatus
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }
    }
`;

export const QUERY_GETMONEY_KZ_LEADS = gql`
    query getMoneyKzLeads($filters: GetMoneyKzLeadFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        getMoneyKzLeads(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    session
                    provider
                    createdAt
                    registerAt
                    loginAt
                    orderAt
                    paidAt
                    refundAt
                    user {
                        data {
                            id
                            attributes {
                                FIO
                                phone
                            }
                        }
                    }
                    status
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }
    }
`;

export const QUERY_GETMONEY_KZ_REG = gql`
    query getMoneyKzReg($id: ID!) {
        getMoneyKzReg(id: $id) {
            data {
                id
                attributes {
                    createdAt
                    FIO
                    phone
                    birthday
                    sex
                    city
                }
            }
        }
    }
`;