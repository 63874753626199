import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    EditOutlined,
    ExportOutlined,
    HolderOutlined,
    PlusOutlined
} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { TableColumnsType } from 'antd';
import {Button, Space, Switch, Table, Tooltip} from 'antd';
import _ from 'lodash';

import {getMoneyKzShowcases, getMoneyKzShowcasesVariables} from '../../../../graphql/__generated__/getMoneyKzShowcases';
import {
    updateGetMoneyKzShowcase,
    updateGetMoneyKzShowcaseVariables
} from '../../../../graphql/__generated__/updateGetMoneyKzShowcase';
import {MUTATION_UPDATE_GETMONEY_KZ_SHOWCASE, QUERY_GETMONEY_KZ_SHOWCASES} from '../../../../graphql/getmoney-kz';
import { imgUrl } from '../../../../helpers/imgUrl';
import {GETMONEY_KZ_PREVIEW, toGetMoneyKzShowcaseEdit} from '../../../../routes/routes';

import cls from './Showcase.module.scss'

interface DataType {
    key: string;
    position: number;
    logo: string;
    headline: string;
    percent: string;
    term: string;
    age: string;
    URL: string;
    free: boolean;
    paid: boolean;
}

interface RowContextProps {
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
    listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
        <Button
            type="text"
            size="small"
            icon={<HolderOutlined />}
            style={{ cursor: 'move' }}
            ref={setActivatorNodeRef}
            {...listeners}
        />
    );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

const Row: React.FC<RowProps> = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: props['data-row-key'] });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    const contextValue = useMemo<RowContextProps>(
        () => ({ setActivatorNodeRef, listeners }),
        [setActivatorNodeRef, listeners],
    );

    return (
        <RowContext.Provider value={contextValue}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
        </RowContext.Provider>
    );
};

export const GetMoneyKZShowcases = () => {

    const [updateShowcase, updateShowcaseData] = useMutation<updateGetMoneyKzShowcase,updateGetMoneyKzShowcaseVariables>(MUTATION_UPDATE_GETMONEY_KZ_SHOWCASE)

    const query = useQuery<getMoneyKzShowcases, getMoneyKzShowcasesVariables>(QUERY_GETMONEY_KZ_SHOWCASES, {
        variables: {
            filters: {
            },
            sort: ['position:ASC'],
            pagination: {
                limit: 10000
            }
        },
    })

    const dataSource:DataType[] = _.map(query.data?.getMoneyKzShowcases?.data, (offer) => (
        {
            key: offer.id!,
            position: offer.attributes?.position!,
            logo: imgUrl(offer.attributes?.logo.data?.attributes?.url!),
            headline: offer.attributes?.headline!,
            percent: offer.attributes?.percent!,
            age: offer.attributes?.age!,
            term: offer.attributes?.term!,
            URL: offer.attributes?.URL!,
            free: offer.attributes?.free!,
            paid: offer.attributes?.paid!,
        }
    ))

    const onDragEnd = async ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {

            const id = active.id
            const position = dataSource.find((record) => record.key === over?.id)?.position

            await updateShowcase({
                variables: {
                    id: id?.toString()!,
                    data: {position: position},
                },
                onCompleted: () => {query.refetch()}
            })
        }
    };

    const navigate = useNavigate();

    const columns:TableColumnsType<DataType> = [
        { key: 'sort', align: 'center', render: () => <DragHandle /> },
        {
            title: '№',
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: 'Логотип',
            dataIndex: 'logo',
            key: 'logo',
            render: (img: string) => (<img src={img} alt={'Logo'} width={140}/>)
        },
        {
            title: 'Заголовок',
            dataIndex: 'headline',
            key: 'headline',
            className: 'bold',
        },
        {
            title: 'Ставка',
            dataIndex: 'percent',
            key: 'percent',
        },
        {
            title: 'Срок',
            dataIndex: 'term',
            key: 'term',
        },
        {
            title: 'Возраст',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: 'URL',
            dataIndex: 'URL',
            key: 'URL',
        },
        {
            title: 'Бесплатно',
            dataIndex: 'free',
            key: 'free',
            render: (item: boolean, record) => (
                <Switch value={item} onChange={(value) => updateShowcaseFree(record.key, value)}/>
            ),
        },
        {
            title: 'Платно',
            dataIndex: 'paid',
            key: 'paid',
            render: (item: boolean, record) => (
                <Switch value={item} onChange={(value) => updateShowcasePaid(record.key, value)}/>
            ),
        },
        {
            key: 'control',
            render: (_:any, record:any) => (
                <Space>
                    <Tooltip title={'Редактировать'}>
                        <Button type={'link'} onClick={() => navigate(toGetMoneyKzShowcaseEdit(record.key))}
                                icon={<EditOutlined/>}/>
                    </Tooltip>
                </Space>
            )
        },
    ];

    const updateShowcaseFree = async (id: string, value: boolean) => {
        await updateShowcase({
            variables: {id: id, data: {free: value}},
            onCompleted: () => {
                query.refetch()
            }
        })
    }
    const updateShowcasePaid = async (id: string, value: boolean) => {
        await updateShowcase({
            variables: {id: id, data: {paid: value}},
            onCompleted: () => {
                query.refetch()
            }
        })
    }

    return (
        <>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Button icon={<PlusOutlined/>} onClick={() =>  navigate(toGetMoneyKzShowcaseEdit('new'))}>Добавить</Button>

                </div>
                <div className={cls.right}>
                    <a href={GETMONEY_KZ_PREVIEW} target={'_blank'} rel={'noreferrer'}>
                        <Button icon={<ExportOutlined/>}>Посмотреть</Button>
                    </a>
                </div>
            </div>

            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext items={dataSource.map((i) => i.key)} strategy={verticalListSortingStrategy}>
                    <Table<DataType>
                        rowKey="key"
                        components={{ body: { row: Row } }}
                        columns={columns}
                        dataSource={dataSource}
                        loading={query.loading || updateShowcaseData.loading}
                    />
                </SortableContext>
            </DndContext>

            {/*<Table dataSource={dataSource1} columns={columns1} loading={query.loading} />*/}
           
        </>

    )

}