import React from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';

import {ONLYCASH_KZ_LEAD, ONLYCASH_KZ_REG} from '../../../routes/routes';

import cls from '../../Home/Home.module.scss'

export const OnlyCashKZMenu = () => (

    <>
        <div className={cls.home}>

            <div className={cls.group}>

            <Link to={ONLYCASH_KZ_REG} className={cn([cls.card, cls.bgTwo])}>Регистрации</Link>
            <Link to={ONLYCASH_KZ_LEAD} className={cn([cls.card, cls.bgThree])}>Лиды</Link>

            </div>

        </div>
    </>

)