import React from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';

import {
    GETMONEY_KZ_LEADS, GETMONEY_KZ_ORDERS,
    GETMONEY_KZ_REGS,
    GETMONEY_KZ_SHOWCASES
} from '../../../routes/routes';

import cls from '../../Home/Home.module.scss'

export const GetMoneyKZMenu = () => (

    <>
        <div className={cls.home}>

            <div className={cls.group}>

            <Link to={GETMONEY_KZ_REGS} className={cn([cls.card, cls.bgOne])}>Регистрации</Link>
            <Link to={GETMONEY_KZ_LEADS} className={cn([cls.card, cls.bgTwo])}>Лиды</Link>
            <Link to={GETMONEY_KZ_ORDERS} className={cn([cls.card, cls.bgTwo])}>Оплаты</Link>
            <Link to={GETMONEY_KZ_SHOWCASES} className={cn([cls.card, cls.bgThree])}>Витрина</Link>

            </div>

        </div>
    </>

)