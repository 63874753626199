import {useState} from 'react';
import {useQuery} from '@apollo/client';
import {Radio, Skeleton} from 'antd';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';

import {getMoneyKzShowcases, getMoneyKzShowcasesVariables} from '../../../../../graphql/__generated__/getMoneyKzShowcases';
import {QUERY_GETMONEY_KZ_SHOWCASES} from '../../../../../graphql/getmoney-kz';
import {imgUrl} from '../../../../../helpers/imgUrl';

import {Card} from './Card';

import cls from './Cards.module.scss'

export interface IOffer {
    name: string
    logo: string
    percent: string
    date: string
    value: string
    age: string
    URL: string
}

export const Cards = observer(() => {

    const [type, setType] = useState('free')

    const query = useQuery<getMoneyKzShowcases, getMoneyKzShowcasesVariables>(QUERY_GETMONEY_KZ_SHOWCASES, {
        variables: {
            filters: {
                ...(type === 'free' ? {free: {eq: true}} : {}),
                ...(type === 'paid' ? {paid: {eq: true}} : {}),
            },
            sort: ['position:ASC'],
            pagination: {
                limit: 10000
            }
        },
    })
    if (query.loading) return <Skeleton active/>

    const items: IOffer[] = _.map(query.data?.getMoneyKzShowcases?.data, offer => (
        {
            name: offer.id!,
            logo: imgUrl(offer.attributes?.logo.data?.attributes?.url!),
            percent: offer.attributes?.percent!,
            date: offer.attributes?.term!,
            value: offer.attributes?.headline!,
            age: offer.attributes?.age!,
            URL: offer.attributes?.URL!,
        }
    ))

    return (
        <div className={cls.page}>

            <Radio.Group value={type} onChange={(e) => setType(e.target.value)} optionType={'button'}
                         buttonStyle={'solid'}>
                <Radio value={'free'}>Бесплатно</Radio>
                <Radio value={'paid'}>Платно</Radio>
            </Radio.Group>

            <div className={cls.cards}>
                {_.map(items, (item) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Card key={item.name} {...item}/>
                ))}
            </div>
        </div>
    )
})